code {
  padding: 5px 10px;
  display: inline-block;
}

.container-a {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: center;
  margin: 64px 0;
}

.field-a {
  width: 45px;
  height: 60px;

  font: normal normal bold 22px/27px JosefinSans;
  letter-spacing: 0px;
  color: #5d5d5d;

  text-align: center;
  outline: none;
  border-radius: 5px;

  background: #ededed 0% 0% no-repeat padding-box;

  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
  margin-left: 9px;
}

/* @media screen and (min-width: 767px) {
  .field-a {
    width: 82px;
    height: 100px;
  
    font: normal normal bold 32px/37px JosefinSans;
    letter-spacing: 0px;
    color: #5d5d5d;
  
    text-align: center;
    outline: none;
    border-radius: 5px;
  
    background: #ededed 0% 0% no-repeat padding-box;
  
    transition-property: color, border, box-shadow, transform;
    transition-duration: 250ms;
    margin-left: 15px;
  }
 } */



.field-a:first-child {
  margin-left: 0px;
}

.field-a:focus {
  outline: none;
  box-shadow: 0 0 7px rgba(#00e0d7, 0.5);
  border: 2px solid #00e0d7;
  transform: scale(1.05);
}

.field-a.-error {
  outline: none;
  box-shadow: 0 0 7px rgba(#d1286a, 0.5);
  border: 1px solid #d1286a;
  transform: scale(1.05);
}

.field-a-complete {
  border: 1px solid #6ab04c;
  color: #6ab04c;
}

.field-a-complete[disabled] {
  background: rgba(#6ab04c, 0.1);
  opacity: 0.5;
  cursor: not-allowed;
}
